.header {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
}

.main-title {
    animation: App-fade-in 1s linear;
}

@keyframes App-fade-in {
    from {
        opacity: 0;
        transform: translate(0, 35%);
    }
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}
