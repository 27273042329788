/**
 * Start Vanilla CSS 1.0.2
 */
body {
    font: 16pt/1.5em sans-serif;
    line-height: 1;
}
pre, code, tt {
    font: 1em/1.5em 'Andale Mono', 'Lucida Console', monospace;
}
h1, h2, h3, h4, h5, h6, b, strong {
    font-weight: bold;
}
em, i, dfn {
    font-style: italic;
}
dfn {
    font-weight:bold;
}
p, code, pre, kbd {
    margin:0 0 1.5em 0;
}
blockquote {
    margin:0 1.5em 1.5em 1.5em;
}
cite {
    font-style: italic;
}
li ul, li ol {
    margin:0 1.5em;
}
ul, ol {
    margin:0 1.5em 1.5em 1.5em;
}
ol, ul {
    list-style: none;
}
dl {
    margin:0 0 1.5em 0;
}
dl dt {
    font-weight:bold;
}
dd {
    margin-left:1.5em;
}
table {
    margin-bottom:1.4em;
    width:100%;
}
th {
    font-weight:bold;
}
th, td, caption {
    padding:4px 10px 4px 5px;
}
tfoot {
    font-style:italic;
}
sup, sub {
    line-height:0;
}
abbr, acronym {
    border-bottom: 1px dotted;
}
address {
    margin:0 0 1.5em;
    font-style:italic;
}
del {
    text-decoration: line-through;
}
pre {
    margin:1.5em 0;
    white-space:pre;
}
img.centered, .aligncenter, div.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
img.alignright {
    display: inline;
}
img.alignleft {
    display: inline;
}
.alignright {
    float: right;
    margin-left: 10px;
}
.alignleft {
    float: left;
    margin-right: 10px;
}
img {
    max-width: 100%;
}
* html .clearfix {
    height: 1%;
}
* + html .clearfix {
    display: inline-block;
}
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
* html .group {
    height: 1%;
}
* + html .group {
    display: inline-block;
}
.group:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
/**
* End Vanilla CSS
*/
